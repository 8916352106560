import React from "react";
import {useProductAttributes} from "../../../state";
import {PriceInfoSkeleton} from "../Skeleton/PriceInfoSkeleton";

export const PriceInfo = ({maxPriceUtl}) => {
    const {productAttributes, customAttributes, loading, tipProdus, categories} = useProductAttributes() || {};
    const product_type_id = productAttributes?.type_id;

    if (loading) {
        return <PriceInfoSkeleton/>;
    }

    const formatPrice = (price) => {
        return new Intl.NumberFormat('ro-RO', {style: 'currency', currency: 'Lei'}).format(price);
    };

    const regularPriceValue = productAttributes?.price_range?.minimum_price?.regular_price?.value;
    const finalPriceValue = productAttributes?.price_range?.minimum_price?.final_price?.value;

    const regularPriceFormatted = formatPrice(regularPriceValue);
    const finalPriceFormatted = formatPrice(finalPriceValue);

    const ifUtilaje = tipProdus === 'Utilaje';
    const ifPrice = regularPriceValue === 0 || regularPriceValue >= maxPriceUtl;
    const ifSalable = customAttributes?.find(attr => attr.attribute_code === 'info_disp')?.value != 'Nu';
    const isInLichidari = categories?.some(category => category.id === 127);

    const discountValue = productAttributes?.price_range?.minimum_price?.discount.amount_off;
    const discountValueFormatted = formatPrice(discountValue);

    if (ifUtilaje && !isInLichidari) {
        return (
            <>
                {ifPrice || product_type_id === 'bundle' ? (
                    <div></div>
                ) : (
                    regularPriceValue === finalPriceValue ? (
                        <p className={'react-css text-custom-gray text-2xl font-bold font-montserrat mb-2 xl:mb-4 flex flex-row items-end justify-start gap-2'}>
                            {regularPriceFormatted}
                            <span className={'react-css text-text-gray text-sm font-bold font-montserrat leading-6'}>
                            {'TVA inclus'}
                        </span>
                        </p>
                    ) : (
                        <div className={'react-css flex flex-col w-full items-start mb-2 xl:mb-4'}>
                            <div className={'react-css flex flex-wrap justify-start gap-2 items-center w-full'}>
                                <p className={'react-css font-bold text-text-gray text-sm line-through font-montserrat'}>
                                    {regularPriceFormatted}
                                </p>
                                <p className={'react-css bg-main-orange p-1 xl:px-2 xl:py-1 font-montserrat font-bold text-custom-gray ' +
                                    'text-sm rounded-full'}>
                                    {'- '} {discountValueFormatted}
                                </p>
                            </div>
                            <p className={'react-css text-2xl font-bold text-red-500 font-montserrat flex flex-row items-end justify-start gap-2'}>
                                {finalPriceFormatted}
                                <span className={'react-css text-text-gray text-sm font-bold font-montserrat leading-6'}>
                                {'TVA inclus'}
                            </span>
                            </p>
                        </div>
                    )
                )}
            </>
        );
    }

    return (
        <>
            {productAttributes?.custom_attributes.find(attr => attr.attribute_code === 'farapret')?.value === 'fara_cos_pret' ||
            regularPriceValue === 0 || product_type_id === 'bundle' ? (
                <div></div>
            ) : (
                regularPriceValue === finalPriceValue ? (
                    <p className={'react-css text-custom-gray text-2xl font-bold font-montserrat mb-2 xl:mb-4 flex flex-row items-end justify-start gap-2'}>
                        {regularPriceFormatted}
                        <span className={'react-css text-text-gray text-sm font-bold font-montserrat leading-6'}>
                            {'TVA inclus'}
                        </span>
                    </p>
                ) : (
                    <div className={'react-css flex flex-col w-full items-start mb-2 xl:mb-4'}>
                        <div className={'react-css flex flex-wrap justify-start gap-2 items-center w-full'}>
                            <p className={'react-css font-bold text-text-gray text-sm line-through font-montserrat'}>
                                {regularPriceFormatted}
                            </p>
                            <p className={'react-css bg-main-orange p-1 xl:px-2 xl:py-1 font-montserrat font-bold text-custom-gray ' +
                                'text-sm rounded-full'}>
                                {'- '} {discountValueFormatted}
                            </p>
                        </div>
                        <p className={'react-css text-2xl font-bold text-red-500 font-montserrat flex flex-row items-end justify-start gap-2'}>
                            {finalPriceFormatted}
                            <span className={'react-css text-text-gray text-sm font-bold font-montserrat leading-6'}>
                                {'TVA inclus'}
                            </span>
                        </p>
                    </div>
                )
            )}
        </>
    );
}
