import React from 'react';
import {useProductAttributes, findCustomAttributeValue} from '../../../state';
import {CardItem} from "../../../../../general/CardItem";
import {useWindowSize} from "../../../../../general/useWindowSize";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import {Navigation} from 'swiper/modules';
import {SliderSkeleton} from "../Skeleton/SliderSkeleton";

export const AutoRelated = ({maxPriceUtl}) => {
    const {autoRelatedProducts, loading, tipProdus} = useProductAttributes() || {};
    const {isMobile, isTablet} = useWindowSize();
    const itemsToShow = isMobile ? 2 : isTablet ? 4 : 5;
    const gapBetween = isMobile ? 5 : 15;

    if (loading) return <SliderSkeleton/>;

    if (!autoRelatedProducts.length) return null;

    const filteredProducts = autoRelatedProducts.filter(product => {
        const infoDisp = findCustomAttributeValue(product.custom_attributes, 'info_disp');
        return infoDisp !== 'Nu';
    });

    return (
        <div className={'react-css mt-5 xl:mt-14'}>
            <h2 className={'react-css text-custom-gray font-montserrat font-bold text-left text-lg xl:text-2xl mb-0 xl:mb-9'}>
                Produse similare
            </h2>
            <Swiper navigation={false}
                    modules={[Navigation]}
                    slidesPerView={itemsToShow}
                    spaceBetween={gapBetween}
                    freeMode={true}
                    className="react-slider">
                {filteredProducts?.map((product) => (
                    <SwiperSlide key={product.id}>
                        <CardItem product={product} tipProdus={tipProdus} maxPriceUtl={maxPriceUtl}/>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};
