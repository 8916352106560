import React from 'react';
import {useBundleCheckoutState} from "../../../../Checkout";
import {useProductAttributes} from "../../../state";
import {LoadingIcon} from "../../../../../general/assets/LoadingIcon";

export const ProductOptions = ({maxPriceUtl}) => {
    const {bundleOptions, loading, error, total, handleCheckboxChange} = useBundleCheckoutState();
    const {customAttributes, productAttributes, tipProdus, categories} = useProductAttributes();

    if (loading) {
        return (
            <div>
                <LoadingIcon/>
            </div>
        )
    }

    const isBundle = bundleOptions?.find(option => option.__typename === "BundleProduct");
    const bundleOptionsLength = bundleOptions?.[0]?.items?.[0]?.options.length;

    if (!isBundle) return <div></div>;

    const formatPrice = (price) => {
        const roundedPrice = Math.round(price * 100) / 100;
        const adjustedPrice = Math.abs(roundedPrice) < 0.01 ? 0 : roundedPrice;
        return new Intl.NumberFormat('ro-RO', {style: 'currency', currency: 'Lei'}).format(adjustedPrice);
    };

    const optionsTitle = bundleOptions[0]?.items[0]?.title;
    const inputType = bundleOptions[0]?.items[0]?.type;
    const isInStock = productAttributes?.stock_status
    const isUtilaj = tipProdus === 'Utilaje';
    const isInLichidari = categories?.some(category => category.id === 127);

    if (isUtilaj && !isInLichidari) {
        const exceedsMaxPrice = total >= maxPriceUtl;

        return (
            <>
                {isInStock === 'IN_STOCK' && bundleOptionsLength > 0 ? (
                    <div className={'flex flex-col gap-3'}>
                        {/* Afișăm prețul maxim sau totalul în funcție de condiție */}
                        <div className={'react-css text-custom-gray text-2xl font-bold font-montserrat mb-2 ' +
                            'xl:mb-4 flex flex-row items-end justify-start gap-2'}>
                            {formatPrice(total)}
                            <span className={'react-css text-text-gray text-sm font-bold font-montserrat leading-6'}>
                                {'TVA INCLUS'}
                            </span>
                        </div>

                        {bundleOptions.map((bundleOption, index) => (
                            <div key={index} className={'flex flex-col gap-3'}>
                                {!exceedsMaxPrice && bundleOption.__typename === 'BundleProduct' && (
                                    <p className={'react-css text-custom-gray font-nunito text-base xl:text-lg font-bold'}>
                                        {optionsTitle}
                                    </p>
                                )}
                                {bundleOption.items?.map((item, itemIndex) => (
                                    <div className={'flex flex-col items-center xl:items-start gap-3'}
                                         key={itemIndex}>
                                        {item.options.map((option, optionIndex) => (
                                            <div key={optionIndex}
                                                 className={'rounded-full w-[98%] xl:w-full shadow-custom-box-shadow content-center ' +
                                                     'p-5 border-2 border-solid border-border-color hover:border-second-btn hover:shadow-custom-box-shadow'}>
                                                <div className={'flex flex-row justify-between items-center w-full'}>
                                                    {/* Afișăm checkbox doar dacă nu depășim maxPrice */}
                                                    {inputType === 'checkbox' && (
                                                        <>
                                                            {!exceedsMaxPrice ? (
                                                                <div
                                                                    className={'flex flex-row gap-2 items-center w-[70%] '}>
                                                                    <input type="checkbox"
                                                                           defaultChecked={true}
                                                                           className={'m-0 p-0 top-0 w-6 h-6'}
                                                                           onChange={(e) => handleCheckboxChange(
                                                                               option.uid,
                                                                               option.product.price_range.minimum_price.final_price.value,
                                                                               e.target.checked
                                                                           )}
                                                                    />
                                                                    <p className={'react-css text-custom-gray font-nunito text-base xl:text-lg font-bold ' +
                                                                        'lowercase flex items-center'}>
                                                                        {option.quantity} x {option.product.name}
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className={'flex flex-row gap-2 items-center w-[70%] '}>
                                                                    <p className={'react-css text-custom-gray font-nunito text-base xl:text-lg font-bold ' +
                                                                        'lowercase flex items-center'}>
                                                                        {option.quantity} x {option.product.name}
                                                                    </p>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                    {option.product.price_range.minimum_price.regular_price.value > 0 && (
                                                        <p className={'react-css w-[30%] xl:w-[150px] text-custom-gray font-nunito text-sm xl:text-lg font-bold flex items-center justify-end'}>
                                                            + {formatPrice(option.product.price_range.minimum_price.final_price.value * option.quantity)}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                ) : (
                    <div></div>
                )}
            </>
        );
    }

    return (
        <>
            {customAttributes?.find(attr => attr.attribute_code === 'farapret')?.value === 'pret_stoc' &&
            isInStock === 'IN_STOCK' && bundleOptionsLength > 0 ? (
                <div className={'flex flex-col gap-3'}>
                    <div
                        className={'react-css text-custom-gray text-2xl font-bold font-montserrat mb-2 xl:mb-4 flex flex-row items-end justify-start gap-2'}>
                        {formatPrice(total)}
                        <span className={'react-css text-text-gray text-sm font-bold font-montserrat leading-6'}>
                            {'TVA INCLUS'}
                        </span>
                    </div>
                    {bundleOptions.map((bundleOption, index) => (
                        <div key={index}
                             className={'flex flex-col gap-3'}
                        >
                            {bundleOption.__typename === 'BundleProduct' && (
                                <p className={'react-css text-custom-gray font-nunito text-base xl:text-lg font-bold'}>
                                    {optionsTitle}
                                </p>
                            )}
                            {bundleOption.items?.map((item, itemIndex) => (
                                <div className={'flex flex-col items-center xl:items-start gap-3'}
                                     key={itemIndex}>
                                    {item.options.map((option, optionIndex) => (
                                        <div key={optionIndex}
                                             className={'rounded-full w-[98%] xl:w-full shadow-custom-box-shadow content-center p-5 border-2' +
                                                 'border-solid border-border-color hover:border-second-btn hover:shadow-custom-box-shadow'}
                                        >
                                            <div className={'flex flex-row justify-between items-center w-full'}>
                                                <div className={'flex flex-row gap-2 items-center w-[70%] '}>
                                                    {inputType === 'checkbox' && (
                                                        <input type="checkbox"
                                                               defaultChecked={true}
                                                               className={'m-0 p-0 top-0 w-6 h-6'}
                                                               onChange={(e) => handleCheckboxChange(
                                                                   option.uid,
                                                                   option.product.price_range.minimum_price.final_price.value,
                                                                   e.target.checked
                                                               )}
                                                        />
                                                    )}
                                                    <p className={'react-css text-custom-gray font-nunito text-base xl:text-lg font-bold lowercase flex items-center'}>
                                                        {option.quantity} x {option.product.name}
                                                    </p>
                                                </div>
                                                {option.product.price_range.minimum_price.final_price.value > 0 && (
                                                    <p className={'react-css w-[30%] xl:w-[150px] text-custom-gray font-nunito text-sm xl:text-lg font-bold flex items-center justify-end'}>
                                                        + {formatPrice(option.product.price_range.minimum_price.final_price.value * option.quantity)}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>

            ) : (
                <div></div>
            )}
        </>
    );
};


