import React from "react";
import {findCustomAttributeValue, useProductAttributes} from "../../../state";
import {TbiSkeleton} from "../Skeleton/TbiSkeleton";
import {useBundleCheckoutState} from "../../../../Checkout";

export const TbiCard = () => {
    const {tbiParams, customAttributes, productAttributes, loading, tipProdus} = useProductAttributes() || {};
    const {bundleOptions, total} = useBundleCheckoutState();
    if (loading) return <TbiSkeleton/>;

    if (!productAttributes || !tbiParams) {
        return null;
    }

    let tierPrice = tbiParams?.attributes?.tierPrice;
    const minprice_tbiro = parseFloat(tbiParams.attributes.minprice_tbiro);
    const maxprice_tbiro = parseFloat(tbiParams.attributes.maxprice_tbiro);

    const salableProduct = findCustomAttributeValue(customAttributes, 'info_disp') != 'Nu';
    const tbi_status = tbiParams?.attributes?.tbi_status === 'Yes';
    const tbi_btnvisible = tbiParams?.attributes?.tbi_btnvisible === 'Yes';
    const tbi_custom_button_status = tbiParams?.attributes?.tbi_custom_button_status === 'Yes';
    const tbi_vnoska = tbiParams?.attributes?.tbiro_vnoska === 'Yes';
    const tbi_months = tbiParams.attributes?.tbi_months;
    const tbiro_mesecna = tbiParams.attributes?.tbiro_mesecna;
    const tbi_btn_url = tbiParams?.attributes?.tbi_btn_url;
    const tbi_backurl = tbiParams?.attributes?.tbi_backurl;
    const tbi_btn_hover_url = tbiParams?.attributes?.tbi_btn_hover_url;
    const tbi_mod_version = tbiParams?.attributes?.tbi_mod_version;

    if (!tbi_status || !tbi_btnvisible || !tbi_vnoska || !salableProduct) {
        return null;
    }

    const isBundle = bundleOptions?.find(option => option.__typename === "BundleProduct");
    if (isBundle) {
        tierPrice = total
    }

    return (
        <>
            {tierPrice !== 0 && tierPrice >= minprice_tbiro && tierPrice <= maxprice_tbiro && (
                <div className={'react-tbi-card flex flex-col gap-2 xl:gap-4 rounded-xl bg-cards-bg p-5 xl:p-7'}>
                    <p className={'react-css text-custom-gray font-montserrat text-base xl:text-lg font-bold flex items-center gap-1'}>
                        {'Rate de la '}
                        <span className={'text-black-friday-btn'}>{tbiro_mesecna.toFixed(2)} {' Lei'}</span>
                        {'/luna'}
                    </p>

                    <p className={'react-css text-black-friday-btn font-montserrat text-base xl:text-lg font-bold flex items-center'}>
                        {tbiro_mesecna.toFixed(2)} {' Lei '}
                        {'x '}
                        {tbi_months}
                    </p>

                    <p className={'react-css text-custom-gray font-nunito text-base xl:text-lg font-bold flex items-center'}>
                        {'De ce sa astepti? E mai simplu sa cumperi acum si sa platesti mai tarziu, prin solutiile de finantare de la tbi bank.'}
                    </p>

                    <a className={''}
                        href={tbi_backurl} target="_blank" title="Pagina TBI Credit">
                        <img className={'w-full xl:w-3/4'}
                            src={tbi_btn_url}
                            title={`Credit module tbi bank ${tbi_mod_version}`}
                            alt={`Credit module tbi bank ${tbi_mod_version}`}
                            onMouseOver={(e) => e.currentTarget.src = tbi_btn_hover_url}
                            onMouseOut={(e) => e.currentTarget.src = tbi_btn_url}
                        />
                    </a>
                    <div className={'flex flex-row items-stretch justify-start gap-2 xl:gap-4'}>
                        <div className={'mt-[1%] w-[50px] xl:w-[100px]'}>
                            <svg className={'mt-[1%] w-5 h-5 xl:w-8 xl:h-8 fill-current text-gray-500'}
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 576 512">
                                <path
                                    d="M0 432c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V256H0v176zm192-68c0-6.6
                                5.4-12 12-12h136c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-40zm-128
                                0c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM576
                                80v48H0V80c0-26.5 21.5-48 48-48h480c26.5 0 48 21.5 48 48z"/>
                            </svg>
                        </div>

                        <div className={'flex flex-col items-start justify-start'}>
                            <p className={'react-css font-nunito text-text-custom-gray font-bold text-base xl:text-lg '}>
                                {'Plata cu cardul in rate'}
                            </p>
                            <div className={''}>
                                <p className={'react-css font-nunito text-text-custom-gray font-normal text-sm  '}>
                                    {'Rate prin Alpha Bank - pana la 12 rate.'}
                                </p>
                                <p className={'react-css font-nunito text-text-custom-gray text-sm font-normal '}>
                                    {'Rate prin Banca Transilvania - pana la 6 rate.'}
                                </p>
                            </div>
                            <a className={'react-css font-nunito text-custom-gray font-bold text-base xl:text-lg' +
                                'focus:text-custom-gray active:text-custom-gray visited:text-custom-gray hover:text-custom-gray ' +
                                'mt-4 pt-2 border-solid border-black border-t-2 w-full'}
                               href="/cum-platesc#plataRateTbi" target="_blank">
                                {'Afla mai multe detalii'}
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
