import React from 'react';
import {Prezentare} from "./ProductDetails/Prezentare";
import {Specificatii} from './ProductDetails/Specificatii';
import {InformatiiAditionale} from "./ProductDetails/InformatiiAditionale";
import {Review} from './ProductDetails/Review';
import {ReviewForm} from './ProductDetails/ReviewForm';
import {useWindowSize} from '../../../../general';
import {ProductAttributeProvider, useProductAttributes} from '../../state';
import {CrossSellProducts} from "./Related/CrossSellProducts";
import {UpsellProducts} from "./Related/UpsellProducts";
import {MagentoRelated} from "./Related/MagentoRelated";
import {AutoRelated} from "./Related/AutoRelated";

export const ProductDetails = ({maxPriceUtl}) => {
    const {
        appConfig,
        productAttributes,
        shortDescription,
        specificatii,
        customAttributes,
        upsellProducts,
        relatedProducts,
        autoRelatedProducts,
        crossSellProducts,
    } = useProductAttributes() || {};

    const {isMobile} = useWindowSize();
    const numberOfReviews = productAttributes?.reviews?.items.length;

    const scrollToComponent = (componentId: string) => {
        const element = document.getElementById(componentId);
        if (element) {
            element.scrollIntoView({behavior: 'smooth'});
        }
    };

    return (
        <div>
            {isMobile ? (
                // Mobile Layout - All sections open
                <div>
                    <div className={'mb-5'}>
                        <UpsellProducts maxPriceUtl={maxPriceUtl}/>
                        <CrossSellProducts maxPriceUtl={maxPriceUtl}/>
                    </div>

                    <div className={' bg-second-btn-bg bg-gray-300 text-custom-gray'}>
                        <h2 className={'font-montserrat font-bold text-lg text-start text-white focus:text-white leading-[50px] pl-10 pr-10'}>
                            {'Descriere'}
                        </h2>
                    </div>
                    <Prezentare/>

                    <Specificatii/>

                    <div className={' bg-second-btn-bg bg-gray-300 text-custom-gray'}>
                        <h2 className={'font-montserrat font-bold text-lg text-start text-white focus:text-white leading-[50px] pl-10 pr-10'}>
                            {'Informații adiționale'}
                        </h2>
                    </div>
                    <InformatiiAditionale appConfig={appConfig}/>

                    <div className={'bg-second-btn-bg bg-gray-300 text-custom-gray'}>
                        <h2 className={'font-montserrat font-bold text-lg text-start text-white focus:text-white leading-[50px] pl-10 pr-10'}>
                            {'Evaluari'}
                        </h2>
                    </div>
                    <Review/>
                    <ReviewForm/>

                    <div id={'related-list'} className={'mb-9'}>
                        <div className={' bg-second-btn-bg bg-gray-300 text-custom-gray'}>
                            <h2 className={'font-montserrat font-bold text-lg text-start text-white focus:text-white leading-[50px] pl-10 pr-10'}>
                                {'Produse similare'}
                            </h2>
                        </div>
                        <AutoRelated maxPriceUtl={maxPriceUtl}/>
                        <MagentoRelated maxPriceUtl={maxPriceUtl}/>
                    </div>

                </div>
            ) : (
                // Desktop Layout - Tab-based
                <div className={'react-css'}>
                    <div>
                        <UpsellProducts maxPriceUtl={maxPriceUtl}/>
                        <CrossSellProducts maxPriceUtl={maxPriceUtl}/>
                    </div>
                    <div
                        className={'absolute left-0 right-0 w-full flex justify-center items-center h-[60px] ' +
                            'bg-second-btn-bg shadow-second-btn-shadow'}>
                        <div
                            className={'navigation-bar h-[60px] w-[90%] xl:w-[1740px] ' +
                                'mt-3 mb-3 flex flex-row justify-start gap-9'}>
                            {shortDescription ?
                                (
                                    <button
                                        className={'react-css uppercase font-nunito font-bold text-xs xl:text-sm text-white active:bg-second-btn-bg ' +
                                            'focus:bg-second-btn-bg hover:bg-second-btn-bg border-0 hover:border-0 focus:border-0' +
                                            ' tracking-[1.4px] xl:leading-9'}
                                        onClick={() => scrollToComponent('productDescription')}>
                                        Descriere
                                    </button>
                                ) : (
                                    <button
                                        className={'react-css uppercase font-nunito font-bold text-xs xl:text-sm text-white active:bg-second-btn-bg ' +
                                            'focus:bg-second-btn-bg hover:bg-second-btn-bg border-0 hover:border-0 focus:border-0' +
                                            ' tracking-[1.4px] xl:leading-9'}>
                                        Descriere
                                    </button>
                                )
                            }
                            {specificatii &&
                                <button
                                    className={'react-css uppercase font-nunito font-bold text-xs xl:text-sm text-white active:bg-second-btn-bg ' +
                                        'focus:bg-second-btn-bg hover:bg-second-btn-bg border-0 hover:border-0 focus:border-0' +
                                        ' tracking-[1.4px] xl:leading-9'}
                                    onClick={() => scrollToComponent('specificatiiTehnice')}>
                                    Specificatii tehnice
                                </button>
                            }
                            {customAttributes &&
                                <button
                                    className={'react-css uppercase font-nunito font-bold text-xs xl:text-sm text-white active:bg-second-btn-bg ' +
                                        'focus:bg-second-btn-bg hover:bg-second-btn-bg border-0 hover:border-0 focus:border-0' +
                                        ' tracking-[1.4px] xl:leading-9'}
                                    onClick={() => scrollToComponent('additionalInfo')}>
                                    Informații adiționale
                                </button>
                            }
                            {upsellProducts.length > 0 &&
                                <button
                                    className={'react-css uppercase font-nunito font-bold text-xs xl:text-sm text-white active:bg-second-btn-bg ' +
                                        'focus:bg-second-btn-bg hover:bg-second-btn-bg border-0 hover:border-0 focus:border-0' +
                                        ' tracking-[1.4px] xl:leading-9'}
                                    onClick={() => scrollToComponent('upsell')}>
                                    Produse asociate
                                </button>
                            }
                            {crossSellProducts.length > 0 &&
                                <button
                                    className={'react-css uppercase font-nunito font-bold text-xs xl:text-sm text-white active:bg-second-btn-bg ' +
                                        'focus:bg-second-btn-bg hover:bg-second-btn-bg border-0 hover:border-0 focus:border-0' +
                                        ' tracking-[1.4px] xl:leading-9'}
                                    onClick={() => scrollToComponent('crossSell')}>
                                    Accesorii
                                </button>
                            }
                            {(relatedProducts || autoRelatedProducts) && (
                                <button
                                    className={'react-css uppercase font-nunito font-bold text-xs xl:text-sm text-white active:bg-second-btn-bg ' +
                                        'focus:bg-second-btn-bg hover:bg-second-btn-bg border-0 hover:border-0 focus:border-0 ' +
                                        'tracking-[1.4px] xl:leading-9'}
                                    onClick={() => scrollToComponent('related-list')}>
                                    Produse similare
                                </button>
                            )}
                            {numberOfReviews > 0 ?
                                (
                                    <button
                                        className={'react-css uppercase font-nunito font-bold text-xs xl:text-sm text-white active:bg-second-btn-bg ' +
                                            'focus:bg-second-btn-bg hover:bg-second-btn-bg border-0 hover:border-0 focus:border-0 ' +
                                            ' tracking-[1.4px] xl:leading-9'}
                                        onClick={() => scrollToComponent('react-reviews')}>
                                        Evaluari
                                    </button>
                                ) : (
                                    <button
                                        className={'react-css uppercase font-nunito font-bold text-xs xl:text-sm text-white active:bg-second-btn-bg ' +
                                            'focus:bg-second-btn-bg hover:bg-second-btn-bg border-0 hover:border-0 focus:border-0' +
                                            ' tracking-[1.4px] xl:leading-9'}
                                        onClick={() => scrollToComponent('review-form')}>
                                        Scrie o evaluare
                                    </button>
                                )
                            }
                        </div>
                    </div>
                    <div className={'flex justify-center mt-9 mb-9'}>
                        <div className={'w-[1190px] mt-20'}>
                            <Prezentare/>
                            <Specificatii/>
                            <InformatiiAditionale appConfig={appConfig}/>
                            <Review/>
                            <ReviewForm/>
                        </div>
                    </div>
                    <div id={'related-list'} className={'mb-9'}>
                        <AutoRelated maxPriceUtl={maxPriceUtl}/>
                        <MagentoRelated maxPriceUtl={maxPriceUtl}/>
                    </div>
                </div>
            )}
        </div>
    );
};
