import React, {useState} from 'react';
import {useBundleCheckoutState} from "../../../../Checkout";
import {LoadingIcon} from "../../../../../general/assets/LoadingIcon";
import {findCustomAttributeValue, useProductAttributes} from "../../../state";
import {CrmForms} from "../../../../CrmForms/components/CrmForms/CrmForms";
import {ContextFormsProvider} from "../../../../CrmForms";
import {Translate} from "../../../../../general/Translate";

export const BundleAddToCartButton = ({maxPriceUtl}) => {
    const [qty, setQty] = useState(1);
    const [showCrmLeadForm, setShowCrmLeadForm] = useState(false);
    const {
        bundleOptions,
        handleAddBundleToCart,
        addingBundleToCart,
        errorMessage,
        total
    } = useBundleCheckoutState();
    const {
        productAttributes,
        customAttributes,
        productSku,
        tipProdus,
        categories,
    } = useProductAttributes() || {};

    const product_type_id = productAttributes?.type_id;
    const bundleOptionsLength = bundleOptions?.[0]?.items?.[0]?.options.length;
    const isInStock = productAttributes?.stock_status
    const isInLichidari = categories?.some(category => category.id === 127);
    const stockStatus = findCustomAttributeValue(customAttributes, 'status_stock');

    const getButtonText = () => {
        if (isInStock === 'OUT_OF_STOCK' && stockStatus === 'Rezervat') {
            return 'Cere informatii';
        }
        if (isInStock === 'OUT_OF_STOCK') {
            return 'Cere un termen de livrare';
        }
        return 'Cere o oferta';
    };

    const ifUtilaje = tipProdus === 'Utilaje';
    const ifSalable = customAttributes?.find(attr => attr.attribute_code === 'info_disp')?.value != 'Nu';
    const exceedsMaxPrice = total >= maxPriceUtl;

    if(ifUtilaje && product_type_id === 'bundle' && !isInLichidari) {
        return (
            <>
                {ifSalable ? (
                    <div className={'mt-6 mb-6 flex justify-left flex-col'}>
                        <div className={'react-addToCart flex justify-start w-full'}>
                            {exceedsMaxPrice || isInStock === 'OUT_OF_STOCK' ? (
                                    <div>
                                        <button
                                            className={
                                                'flex justify-center items-center h-[50px] min-w-[280px] w-full bg-main-orange focus:outline-none ' +
                                                'focus:bg-main-orange active:bg-main-orange text-white text-xl font-montserrat ' +
                                                'font-bold rounded-full w-4/5 pl-2 pr-2 xl:m-0 hover:bg-main-orange hover:text-white hover:translate-y-1 ' +
                                                'focus:text-white active:text-white shadow-first-btn-shadow border-main-orange transition-all duration-300 ease-in-out'
                                            }
                                            onClick={() => setShowCrmLeadForm(true)}
                                        >
                                            {getButtonText()}
                                        </button>
                                        {showCrmLeadForm && (
                                            <ContextFormsProvider productSku={productSku}>
                                                <div
                                                    className={'fixed inset-0 flex items-center justify-center z-10'}>
                                                    <CrmForms title={'Cere oferta pentru produs'}
                                                              onClose={() => setShowCrmLeadForm(false)}
                                                    />
                                                </div>
                                            </ContextFormsProvider>
                                        )}

                                    </div>
                                ) : (
                                    <div className={'flex flex-row gap-4 w-full xl:w-auto'}>
                                        <input
                                            className={'qty-input bg-cards-bg rounded-xl w-[60px] h-[50px] text-center text-2xl font-montserrat font-bold ' +
                                                'border-2 border-solid border-border-color'}
                                            name={'qty'}
                                            value={qty}
                                            onChange={(e) => setQty(parseInt(e.target.value))}
                                        />
                                        <button
                                            className={
                                                'flex justify-center items-center h-[50px] min-w-[144px] xl:min-w-[280px] w-4/5 bg-main-orange focus:outline-none ' +
                                                'focus:bg-main-orange active:bg-main-orange text-white text-lg xl:text-xl font-montserrat pl-4 pr-4 ' +
                                                'font-bold rounded-full xl:m-0 hover:bg-main-orange hover:text-white hover:border-none hover:translate-y-1 ' +
                                                'focus:text-white active:text-white shadow-first-btn-shadow border-none transition-all duration-300 ease-in-out'
                                            }

                                            onClick={() => handleAddBundleToCart({
                                                sku: productSku,
                                                quantity: qty ? qty : 1
                                            })}
                                        >
                                            {productSku === addingBundleToCart ? (
                                                <>
                                                    <LoadingIcon/>
                                                </>
                                            ) : (
                                                <>
                                                    <svg className={'mr-5'}
                                                         xmlns="http://www.w3.org/2000/svg" height="18" width="18"
                                                         viewBox="0 0 512 512">
                                                        <path fill="#ffffff"
                                                              d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/>
                                                    </svg>
                                                    <span>{'Adauga in cos'}</span>
                                                </>
                                            )}
                                        </button>
                                    </div>
                                )}
                        </div>
                        {errorMessage && (
                            <div className={'text-red-500 text-sm mt-3'}>
                                <Translate message={errorMessage}/>
                            </div>
                        )}
                    </div>

                ) : (
                    <p className={'react-css text-red-500 font-montserrat text-lg xl:text-xl font-bold w-full flex items-center justify-start'}>
                        {'Acest produs nu mai face parte din oferta Magaziei'}
                    </p>
                )}
            </>
        )
    }

    return (
        <>
            {product_type_id === 'bundle' ? (
                <>
                    {customAttributes?.find(attr => attr.attribute_code === 'info_disp')?.value != 'Nu' ? (
                        <div className={'mt-6 mb-6 flex justify-left flex-col'}>
                            <div className={'react-addToCart flex justify-start w-full'}>
                                <>
                                    {customAttributes?.find(attr => attr.attribute_code === 'farapret')?.value != 'pret_stoc' ||
                                    bundleOptionsLength == 0 || isInStock === 'OUT_OF_STOCK' ? (
                                        <div>
                                            <button
                                                className={
                                                    'flex justify-center items-center h-[50px] min-w-[280px] w-full bg-main-orange focus:outline-none ' +
                                                    'focus:bg-main-orange active:bg-main-orange text-white text-xl font-montserrat ' +
                                                    'font-bold rounded-full w-4/5 pl-2 pr-2 xl:m-0 hover:bg-main-orange hover:text-white hover:translate-y-1 ' +
                                                    'focus:text-white active:text-white shadow-first-btn-shadow border-main-orange transition-all duration-300 ease-in-out'
                                                }
                                                onClick={() => setShowCrmLeadForm(true)}
                                            >
                                                {'Cere o oferta'}
                                            </button>
                                            {showCrmLeadForm && (
                                                <ContextFormsProvider productSku={productSku}>
                                                    <div
                                                        className={'fixed inset-0 flex items-center justify-center z-10'}>
                                                        <CrmForms title={'Cere oferta pentru produs'}
                                                                  onClose={() => setShowCrmLeadForm(false)}
                                                        />
                                                    </div>
                                                </ContextFormsProvider>
                                            )}

                                        </div>
                                    ) : (
                                        <div className={'flex flex-row gap-4 w-full xl:w-auto'}>
                                            <input
                                                className={'qty-input bg-cards-bg rounded-xl w-[60px] h-[50px] text-center text-2xl font-montserrat font-bold ' +
                                                    'border-2 border-solid border-border-color'}
                                                name={'qty'}
                                                value={qty}
                                                onChange={(e) => setQty(parseInt(e.target.value))}
                                            />
                                            <button
                                                className={
                                                    'flex justify-center items-center h-[50px] min-w-[144px] xl:min-w-[280px] w-4/5 bg-main-orange focus:outline-none ' +
                                                    'focus:bg-main-orange active:bg-main-orange text-white text-lg xl:text-xl font-montserrat pl-4 pr-4 ' +
                                                    'font-bold rounded-full xl:m-0 hover:bg-main-orange hover:text-white hover:border-none hover:translate-y-1 ' +
                                                    'focus:text-white active:text-white shadow-first-btn-shadow border-none transition-all duration-300 ease-in-out'
                                                }

                                                onClick={() => handleAddBundleToCart({
                                                    sku: productSku,
                                                    quantity: qty ? qty : 1
                                                })}
                                            >
                                                {productSku === addingBundleToCart ? (
                                                    <>
                                                        <LoadingIcon/>
                                                    </>
                                                ) : (
                                                    <>
                                                        <svg className={'mr-5'}
                                                             xmlns="http://www.w3.org/2000/svg" height="18" width="18"
                                                             viewBox="0 0 512 512">
                                                            <path fill="#ffffff"
                                                                  d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/>
                                                        </svg>
                                                        <span>{'Adauga in cos'}</span>
                                                    </>
                                                )}
                                            </button>
                                        </div>
                                    )}
                                </>
                            </div>
                            {errorMessage && (
                                <div className={'text-red-500 text-sm mt-3'}>
                                    <Translate message={errorMessage}/>
                                </div>
                            )}
                        </div>
                    ) : (
                        <p className={'react-css text-red-500 font-montserrat text-lg xl:text-xl font-bold w-full flex items-center justify-start'}>
                            {'Acest produs nu mai face parte din oferta Magaziei'}
                        </p>
                    )}
                </>

            ) : (
                <div></div>
            )
            }
        </>
    );
};
