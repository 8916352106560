import React, {createContext, useContext, useEffect, useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import {request} from '../../../Infrastructure';
import {getBnrRates} from '../../queries';

type Rate = {
    currency_from: string;
    currency_to: string;
    rate: number;
    rate_date: string;
};

type RatesContextType = {
    rates: Rate[];
    loading: boolean;
    error: any;
};

const RatesContext = createContext<RatesContextType | undefined>(undefined);

export const RatesProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [bnrRates, setBnrRates] = useState<Rate[]>([]);

    const {data, isLoading, error} = useQuery({
        queryKey: ['bnrRates'],
        queryFn: async () => {
            return request(getBnrRates);
        },
        enabled: true,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (data?.data?.getBnrRates) {
            setBnrRates(data.data.getBnrRates);
        }
    }, [data]);

    const value = {
        rates: bnrRates,
        loading: isLoading,
        error
    };

    return (
        <RatesContext.Provider value={value}>
            {children}
        </RatesContext.Provider>
    );
};

export const useRates = () => {
    const context = useContext(RatesContext);
    if (context === undefined) {
        throw new Error('useRates must be used within a RatesProvider');
    }
    return context;
};
