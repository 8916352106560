import React from 'react';
import {useRates} from "../../state";
import {RatesProvider} from "../../state";
import {QueryClientProvider} from "@tanstack/react-query";
import {client} from "../../../Infrastructure/models/client";

const queryClient = client();

const RatesTable = () => {
    const {rates, loading, error} = useRates();

    if (loading) {
        return (
            <div className="flex items-center justify-center p-8">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="text-red-500 p-4">
                Error loading rates: {error.message}
            </div>
        );
    }

    return (
        <div className="p-4">
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white">
                    <thead>
                    <tr className="bg-gray-100">
                        <th className="p-4 text-left">Currency</th>
                        <th className="p-4 text-left">Rate (RON)</th>
                        <th className="p-4 text-left">Rate Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {rates.map((rate, index) => (
                        <tr key={rate.currency_from} className={index % 2 === 0 ? 'bg-gray-50' : ''}>
                            <td className="p-4">{rate.currency_from}</td>
                            <td className="p-4">{rate.rate.toFixed(4)}</td>
                            <td className="p-4">{rate.rate_date}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export const RatesTableWrap: React.FC = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <RatesProvider>
                <RatesTable/>
            </RatesProvider>
        </QueryClientProvider>
    );
};
