import React from 'react';
import {ProductAttributeProvider} from '../../state';
import {QueryClientProvider} from "@tanstack/react-query";
import {client} from "../../../Infrastructure/models/client";
import {CheckoutProvider} from "../../../Checkout";
import {ProductDetails} from "./ProductDetails";

interface ProductSku {
    productSku: string;
    maxPriceUtl?: number;
}

const queryClient = client();

export const ProductView: React.FC<ProductSku> = ({productSku, maxPriceUtl}) => {

    return (
        <QueryClientProvider client={queryClient}>
            <CheckoutProvider>
                <ProductAttributeProvider productSku={productSku}>
                    <ProductDetails maxPriceUtl={maxPriceUtl}/>
                </ProductAttributeProvider>
            </CheckoutProvider>
        </QueryClientProvider>
    );
};
