import React, {useState, useEffect} from "react";
import {useProductAttributes} from "../../../state";
import {LinkedSkeleton} from "../Skeleton/LinkedSkeleton";

export const LinkedProducts = ({maxPriceUtl}) => {
    const {
        linkedProducts,
        productSku,
        loading,
        tipProdus,
        customAttributes,
        categories,
        productAttributes
    } = useProductAttributes() || {};
    const [selectedProductId, setSelectedProductId] = useState(null);

    useEffect(() => {
        if (linkedProducts && productSku) {
            const initialSelectedProduct = linkedProducts.find(product => product.sku === productSku);
            if (initialSelectedProduct) {
                setSelectedProductId(initialSelectedProduct.id);
            }
        }
    }, [linkedProducts, productSku]);

    if (loading) return <LinkedSkeleton/>;

    if (!linkedProducts || linkedProducts.length <= 1) {
        return null;
    }

    const sortedLinkedProducts = linkedProducts.slice().sort((a, b) => a.custom_linked_position - b.custom_linked_position);
    const productLabel = sortedLinkedProducts.length > 0 ? sortedLinkedProducts[0].produs_imperecheat_label : '';

    const handleProductClick = (productId, url) => {
        setSelectedProductId(productId);
        window.location.href = `${window.BASE_URL}${url}.html`;
    };

    const product_type_id = productAttributes?.type_id;
    const ifUtilaje = tipProdus === 'Utilaje';
    const ifSalable = customAttributes?.find(attr => attr.attribute_code === 'info_disp')?.value != 'Nu';
    const isInLichidari = categories?.some(category => category.id === 127);

    if (product_type_id != 'bundle' && ifUtilaje && !isInLichidari) {
        return (
            <div className={'react-css flex flex-col gap-2 items-left mb-2 xl:mb-4'} id={'react-liked-products'}>
                {productLabel && (
                    <p className={'react-css text-custom-gray font-nunito text-sm xl:text-base font-bold'}>
                        {productLabel}
                    </p>
                )}
                {sortedLinkedProducts.map((product, index) => (
                    <>
                        {ifSalable ? (
                            <div key={product.id}
                                 className={`rounded-full border-solid border-2 border-border-color
                                ${selectedProductId === product.id ? 'border-[#6cbccb] text-[#6cbccb] shadow-linked-shadow' : 'hover:border-[#6cbccb] hover:shadow-linked-shadow'}
                                content-center p-3 w-full cursor-pointer`}
                                 onClick={() => handleProductClick(product.id, product.url_key)}
                            >
                                <div className={'flex flex-row justify-between items-center w-full'}>
                                    <p className={`react-css w-4/5 font-nunito text-sm xl:text-base font-bold lowercase flex items-center
                                     ${selectedProductId === product.id ? 'text-[#6cbccb]' : 'text-custom-gray'}`}>
                                        {product.nume_alternativ}
                                    </p>
                                    {(index === 0 ? product.final_price > 0 : product.final_price < maxPriceUtl && product.final_price > 0) ? (
                                        <p className={'react-css w-1/5 min-w-[90px] xl:min-w-[130px] text-custom-gray font-nunito text-sm xl:text-base font-bold w-1/4 flex items-center justify-end'}>
                                            {parseFloat(product.final_price).toFixed(2)}{' LEI'}
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                        ): null}
                    </>
                ))}
            </div>
        )
    }

    return (
        <div className={'react-css flex flex-col gap-2 items-left mb-2 xl:mb-4'} id={'react-liked-products'}>
            {productLabel && (
                <p className={'react-css text-custom-gray font-nunito text-sm xl:text-base font-bold'}>
                    {productLabel}
                </p>
            )}
            {sortedLinkedProducts.map(product => (
                <div key={product.id}
                     className={`rounded-full border-solid border-2 border-border-color
                                ${selectedProductId === product.id ? 'border-[#6cbccb] text-[#6cbccb] shadow-linked-shadow' : 'hover:border-[#6cbccb] hover:shadow-linked-shadow'}
                                content-center p-3 w-full cursor-pointer`}
                     onClick={() => handleProductClick(product.id, product.url_key)}
                >
                    <div className={'flex flex-row justify-between items-center w-full'}>
                        <p className={`react-css w-4/5 font-nunito text-sm xl:text-base font-bold lowercase flex items-center
                                     ${selectedProductId === product.id ? 'text-[#6cbccb]' : 'text-custom-gray'}`}>
                            {product.nume_alternativ}
                        </p>
                        {product.final_price > 0 && (
                            <p className={'react-css w-1/5 min-w-[90px] xl:min-w-[130px] text-custom-gray font-nunito text-sm xl:text-base font-bold w-1/4 flex items-center justify-end'}>
                                {parseFloat(product.final_price).toFixed(2)}{' LEI'}
                            </p>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};
